import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { mapEdgesToNodes } from "../lib/helpers";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import SEO from "../components/seo";
import Container from "../components/container";
import Elevator from "../components/elevator";
import Layout from "../components/layout";
import "../styles/layout.scss";

import arrowRight from "../assets/arrow-right.svg";

export const query = graphql`
  query AboutPageQuery {
    aboutPage: allSanityPage(filter: { name: { eq: "Om Oss" } }) {
      edges {
        node {
          name
          _rawBody
          _rawExtraBody
          _id
          image {
            alt
            asset {
              _id
              url
            }
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
          }
        }
      }
    }
    coaches: allSanityCoach {
      edges {
        node {
          _id
          name
          extraImage {
            asset {
              _id
              url
            }
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            alt
          }
        }
      }
    }
  }
`;

const AboutPage = () => {
  const data = useStaticQuery(query);
  const coaches = mapEdgesToNodes(data.coaches);
  const coach1 = coaches[0] || [];
  const coach2 = coaches[1] || [];

  const pageEdge = mapEdgesToNodes(data.aboutPage);
  const elevatorContent = pageEdge[0] || [];

  console.log(coaches);

  return (
    <Layout>
      <SEO title="Om Oss" />
      <div className="elevator__background"></div>
      <Container>
        <div className="about">
          <Elevator
            title={elevatorContent.name}
            image={elevatorContent.image}
            info={elevatorContent._rawBody}
            extraInfo={elevatorContent._rawExtraBody}
          />
          <div className="about__coach-wrapper">
            <div className="about__coach">
              <Link className="about__coach-link" to="/om-oss/lopetreneren">
                <h3 className="about__coach-title">Om løpetreneren</h3>
                <img
                  className="about__coach-arrow"
                  src={arrowRight}
                  alt="gå videre til om løpetreneren"
                />
                {coach1.extraImage && coach1.extraImage.asset && (
                  <img
                    className="about__coach-img"
                    src={imageUrlFor(buildImageObj(coach1.extraImage))
                      .width(600)
                      .height(600)
                      .fit("crop")
                      .auto("format")
                      .url()}
                    alt={coach1.extraImage.alt}
                  />
                )}
              </Link>
            </div>
            <div className="about__coach">
              <Link className="about__coach-link" to="/om-oss/sykkeltreneren">
                <h3 className="about__coach-title">Om sykkeltreneren</h3>
                <img
                  className="about__coach-arrow"
                  src={arrowRight}
                  alt="gå videre til om sykkeltreneren"
                />
                {coach2.extraImage && coach2.extraImage.asset && (
                  <img
                    className="about__coach-img"
                    src={imageUrlFor(buildImageObj(coach2.extraImage))
                      .width(600)
                      .height(600)
                      .fit("crop")
                      .auto("format")
                      .url()}
                    alt={coach2.extraImage.alt}
                  />
                )}
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default AboutPage;
